// Code for Chatbot SubAddress Component...

// Implemented by Akash Kumar S...

import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import axios from "axios";
// import Styles from "./ChatbotsubAddressModule.css";
// import ChatBotConextApi from "../Components/Context/ChatBotConextApi";

function ChatbotSubAddressComponent(props) {
  // const authCtx = useContext(ChatBotConextApi);
  // const data = authCtx.contextValue.data;
  // const subdata = authCtx.contextValue.subdata;
  const { steps } = props;

  const {
    address,
    address2,
    TypeCountry,
    Typestate,
    TypeCity,
    Typelandmark,
    TypePincode,
  } = steps;

  // const [state, setState] = useState({
  //   address: "",
  //   address2: "",
  //   TypeCountry: "",
  //   Typestate: "",
  //   TypeCity: "",
  //   Typelandmark: "",
  //   TypePincode: "",
  // });
  // const[StoreDescription,setStoreDescription]=useState("")

  useEffect(() => {
    // setState({
    //   address,
    //   address2,
    //   TypeCountry,
    //   Typestate,
    //   TypeCity,
    //   Typelandmark,
    //   TypePincode,
    // });
    console.log(TypePincode, "TypePincode1");
    if (TypePincode !== "") {
      props.triggerNextStep({ trigger: "confirmation" });
    }

    // const NewAddress = async () => {
    //   var DesContent =
    //     "address:" +
    //     address.value +
    //     "," +
    //     "address2:" +
    //     address2.value +
    //     "," +
    //     "country:" +
    //     TypeCountry.value +
    //     "," +
    //     "state:" +
    //     Typestate.value +
    //     "," +
    //     "city:" +
    //     TypeCity.value +
    //     "," +
    //     "landmark:" +
    //     Typelandmark.value +
    //     "," +
    //     "pincode:" +
    //     TypePincode.value +
    //     ",";

    //   try {
    //     const response = await axios.post(
    //       "https://erp.impactproducts.in/Chatbot_Api/Book_not_received/save_new_ticket",

    //       {
    //         //  entry: "9605490510"
    //         sub_no: subdata,
    //         received_mode: "chatbot",
    //         description: DesContent,
    //         login_id: "1",
    //         received_date: new Date(),
    //         ticket_type: "Address Change",
    //       }
    //     );

    //     console.log(response.data, "tickets");
    //     // setTicketsRaised(response.data);
    //     if (response.data !== "") {
    //       props.triggerNextStep({ trigger: "confirmation" });
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    // NewAddress();
  }, []);

  return (
    <div>
      <div style={{ textAlign: "center", color: "whitesmoke" }}>
        <h4 style={{ color: "whitesmoke" }}> DETAILS</h4>

        <table>
          <tbody className="changeAddress">
            <tr>
              <td>Address</td>
              <td>: </td> <td> {address.value}</td>
            </tr>
            <tr>
              <td>Address2</td>
              <td>: </td> <td> {address2.value}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>: </td> <td> {TypeCity.value}</td>
            </tr>
            <tr>
              <td>LandMark</td>
              <td>: </td> <td> {Typelandmark.value}</td>
            </tr>

            <tr>
              <td>State</td>
              <td>: </td> <td> {Typestate.value}</td>
            </tr>
            <tr>
              <td>Country</td>
              <td>: </td> <td> {TypeCountry.value}</td>
            </tr>
            <tr>
              <td>Pincode</td>
              <td>: </td> <td> {TypePincode.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ChatbotSubAddressComponent;
