// Code for Displaying Chatbot steps

// Implemented by Akash Kumar S

import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import BotImages from "../Assets/impact_logo_i-01.svg";

import ChatbotComponent from "../Components/ChatbotComponent";
import ChatbotBookComponent from "../Components/ChatbotBookComponent";
import ChatbotBookTicketComponent from "../Components/ChabotBookTicketComponent";
import ChatbotAddressComponent from "../Components/ChatbotAddressComponent";
import ChatbotSubAddressComponent from "../Components/ChatbotSubAddressComponent";
import ChatbotAddressRaiseComponent from "../Components/ChatbotAddressRaiseComponent";
import ChatbotRenewComponent from "../Components/ChatbotRenewComponent";
import ChatbotSubscriptionComponent from "../Components/ChatbotSubscriptionComponent";

function SimpleForm(props) {
  const config = {
    width: "400px",
    height: "75vh",
    floating: true,
    botAvatar: BotImages,
  };

  const theme = {
    background: "#f5f8fb",
    fontFamily: "Roboto",
    headerBgColor: "#e40046",
    headerFontColor: "#fff",
    headerFontSize: "18px",
    botBubbleColor: "#e40046",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  var NewArray = [
    {
      id: "subscribe new magazine",
      options: [
        {
          value: "subscribe",
          label: "Subscribe new magazine",
          trigger: "EnterName",
        },
        {
          value: "existing",
          label: "Queries about existing subscription",
          trigger: "1",
        },
      ],
    },

    {
      id: "EnterName",
      message: "Enter your name",
      trigger: "name",
    },
    {
      id: "name",
      user: true,
      validator: (value) => {
        if (/^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(value)) {
          return true;
        } else {
          return "Please input alphabet characters only.";
        }
      },
      trigger: "Entermobile",
    },
    {
      id: "Entermobile",
      message: "Enter your mobile number",
      trigger: "mobile",
    },
    {
      id: "mobile",
      user: true,

      validator: (value) => {
        if (
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
            value
          )
        ) {
          return true;
        } else {
          return "Please input 10 digit number.";
        }
      },
      trigger: "EnterEmail",
    },
    {
      id: "EnterEmail",
      message: "Enter your email address",
      trigger: "email",
    },
    {
      id: "email",
      user: true,

      validator: (value) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          return true;
        } else {
          return "Please enter a valid email.";
        }
      },
      trigger: "ChatbotNewSubscriptionComponent",
    },
    {
      id: "ChatbotNewSubscriptionComponent",
      asMessage: true,
      component: <ChatbotSubscriptionComponent />,
      trigger: "callback",
    },
    {
      id: "callback",
      message: "You will get a call back in 24 hours",
      trigger: "Back",
    },

    {
      id: "existing subscription",
      options: [
        {
          value: "existing",
          label: "Queries about existing subscription",
          trigger: "1",
        },
      ],
    },
    {
      id: "1",
      message: "Enter your Mobile number or Subscription number",
      trigger: "entry",
    },
    {
      id: "entry",
      user: true,
      trigger: "ChatbotComponent",
    },

    {
      id: "ChatbotComponent",
      asMessage: true,
      component: <ChatbotComponent />,
      waitAction: true,

      trigger: "queries",
    },

    {
      id: "queries",

      options: [
        { value: 1, label: " Book not received", trigger: "5" },
        { value: 2, label: "Address change", trigger: "AddressDetails" },

        { value: 3, label: "Renew Magazine", trigger: "renewStep1" },
        {
          value: 4,
          label: "Go back to main menu",
          trigger: "subscribe new magazine",
        },
      ],
    },
    {
      id: "5",
      component: <ChatbotBookComponent />,
      waitAction: true,
      asMessage: true,
      // trigger: "Book not received Response",
    },

    {
      id: "Book not received Response",
      options: [
        {
          value: 1,
          label: "Raise a ticket",
          trigger: "TicketRaiseConfirmation",
        },
      ],
    },

    {
      id: "No data",
      message:
        "You don't have any active subscription or dispatch for this month",
      trigger: "queries",
    },
    {
      id: "processing",
      message:
        "Wait a few days to receive the magazine, while the dispatch will happen in a short period.",
      trigger: "queries",
    },
    {
      id: "TicketRaiseConfirmation",
      message: "Unnecessary ticket raising will lead to account suspension",
      trigger: "continue message",
    },
    {
      id: "continue message",
      message: "Do you want to continue ?",
      trigger: "Yes/No Confirmation",
    },
    {
      id: "Yes/No Confirmation",
      options: [
        { value: "YesConfirmation", label: "yes", trigger: "TicketApiCalling" },
        { value: "NoConfirmation", label: "no", trigger: "queries" },
      ],
    },
    {
      id: "TicketApiCalling",
      component: <ChatbotBookTicketComponent />,
      asMessage: true,
      waitAction: true,
      trigger: "End",
    },

    {
      id: "AddressDetails",
      component: <ChatbotAddressComponent />,
      asMessage: true,

      waitAction: true,

      trigger: "ChangeAddress",
    },
    {
      id: "ChangeAddress",
      message: "Do you want to change your address ?",
      trigger: "ChangeAddressResponse",
    },
    {
      id: "ChangeAddressResponse",
      options: [
        { value: "yes", label: "yes", trigger: "yesAddress" },
        { value: "no", label: "no", trigger: "no" },
      ],
    },

    {
      id: "no",
      message: "Thank you",
      trigger: "updatedno",
    },

    {
      id: "yesAddress",
      message: "Enter your address",
      trigger: "address",
    },

    {
      id: "address",
      user: true,

      trigger: "chatbotAddress1",
    },

    {
      id: "chatbotAddress1",
      message: "Enter your address2",
      trigger: "address2",
    },
    {
      id: "address2",
      user: true,

      trigger: "city",
    },

    {
      id: "city",
      message: "Enter your city",
      trigger: "TypeCity",
    },

    {
      id: "TypeCity",
      user: true,
      trigger: "landmark",
    },
    {
      id: "landmark",
      message: "Enter your landmark",
      trigger: "Typelandmark",
    },
    {
      id: "Typelandmark",
      user: true,
      trigger: "pincode",
    },

    {
      id: "pincode",
      message: "Enter your pincode",
      trigger: "TypePincode",
    },
    {
      id: "TypePincode",
      user: true,
      validator: (value) => {
        if (/^[1-9][0-9]{5}$/.test(value)) {
          return true;
        } else {
          return "Invalid pincode.";
        }
      },
      trigger: "state",
    },

    {
      id: "state",
      message: "Enter your state",
      trigger: "Typestate",
    },
    {
      id: "Typestate",
      user: true,
      trigger: "country",
    },

    {
      id: "country",
      message: "Enter your country",
      trigger: "TypeCountry",
    },
    {
      id: "TypeCountry",
      user: true,
      trigger: "ChatbotSubAddressComponent",
    },

    {
      id: "ChatbotSubAddressComponent",
      component: <ChatbotSubAddressComponent />,
      asMessage: true,
      waitAction: true,
      trigger: "confirmation",
    },

    {
      id: "confirmation",
      message: "Do you want to continue with this address ?",
      trigger: "MakingConfirmation",
    },
    {
      id: "MakingConfirmation",
      options: [
        { value: "confirm message", label: "Confirm", trigger: "confirmed" },
        {
          value: "EditMessage",
          label: "Edit",
          trigger: "yesAddress",
        },
        {
          value: "Back to queries",
          label: "Back to queries",
          trigger: "queries",
        },
      ],
    },
    {
      id: "confirmed",

      component: <ChatbotAddressRaiseComponent />,
      asMessage: true,
      trigger: "queries",
    },

    {
      id: "change further queries",
      options: [
        { value: "yesupdatedqueries", label: "yes", trigger: "updatedyes" },
        { value: "noupdatedqueries", label: "no", trigger: "updatedno" },
      ],
    },

    {
      id: "updatedyes",
      message: "Select your queries",
      trigger: "queries",
    },

    {
      id: "updatedno",
      message: "Thanks for chatting with me, hope I was able to help you.",
      trigger: "Back",
    },
    {
      id: "Back",
      options: [
        {
          value: "Backmenu",
          label: "Back to Main Menu",
          trigger: "subscribe new magazine",
        },
      ],
    },

    {
      id: "renewStep1",
      message: "Are you sure you want to continue ?",
      trigger: "renewStep1Response",
    },
    {
      id: "renewStep1Response",
      options: [
        { value: "yes", label: "yes", trigger: "renewYes" },
        { value: "no", label: "no", trigger: "renewNo" },
      ],
    },

    {
      id: "renewNo",
      message: "Thank you",
      trigger: "1",
    },

    {
      id: "renewYes",
      component: <ChatbotRenewComponent />,
      asMessage: true,

      trigger: "queries",
    },

    {
      id: "End",
      message: "Is there anything else I can help you with?",
      trigger: "EndResponse",
    },
    {
      id: "EndResponse",

      options: [
        { value: "yesupdatedqueries", label: "yes", trigger: "updatedyes" },
        { value: "noupdatedqueries", label: "no", trigger: "updatedno" },
      ],
    },
  ];

  return (
    <div className="ChatBotElements">
      <ThemeProvider theme={theme}>
        <ChatBot
          steps={NewArray}
          {...config}
          enableMobileAutoFocus={true}
          bubbleStyle={{
            maxWidth: "80%",
            fontSize: "clamp(12px, 2.5vw, 15px)",
            letterSpacing: ".8px",
          }}
          bubbleOptionStyle={{
            fontSize: "clamp(12px, 2.5vw, 15px)",
            letterSpacing: "0.8px",
          }}
          customStyle={{ backgroundColor: "#e40046" }}
          enableSmoothScroll={true}
          opened={true}
        />
      </ThemeProvider>
    </div>
  );
}
export default SimpleForm;
