// Code for chatbot Address Component..

// Implemented by AKash Kumar S

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import ChatBotConextApi from "../Components/Context/ChatBotConextApi";

function ChatbotAddressComponent(props) {
  const [query, setQuery] = useState([]);
  console.log(query, "QUERIES");

  const authCtx = useContext(ChatBotConextApi);
  const data = authCtx.contextValue.data;
  const SubData = authCtx.contextValue.subdata;

  console.log("addressData", data);

  const [Address, setAddress] = useState([]);

  const [state, setState] = useState("");
  console.log(state, "state");

  useEffect(() => {
    const { steps } = props;
    const { query } = undefined || steps || {};

    if (steps != undefined) {
      console.log(steps.queries, "steps");
      const { query } = steps;

      setQuery(query);
      console.log(query, "que");
    } else {
      console.log("sdlfhshdb");
    }

    const AddressChange = async () => {
      console.log(props.changed, "props789");

      setState(props.changed);
      console.log(state, "state1234");

      if (SubData) {
        console.log(props.changed, "props");

        try {
          const response = await axios.post(
            "https://erp.impactproducts.in/Chatbot_Api/Book_not_received/get_address",

            {
              sub_no: SubData,
            }
          );

          console.log(response.data.data, "adress change");
          setAddress(response.data.data);
          if (response.data !== "") {
            props.triggerNextStep({ trigger: "ChangeAddress" });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    AddressChange();
  }, []);

  return (
    <div>
      <h5 style={{ textAlign: "center", color: "whitesmoke" }}>
        Address Details
      </h5>

      {Address &&
        Address.map((item, index) => {
          return (
            <div className="AddressContainer" key={index}>
              <p>{item.address}</p>
              <p>{item.address2}</p>
              <p>
                {item.landmark} ,{item.city}{" "}
              </p>

              <p>
                {item.state} - {item.pincode}
              </p>
              <p>{item.country}</p>
            </div>
          );
        })}
    </div>
  );
}

export default ChatbotAddressComponent;
