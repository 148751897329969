// RenewComponent page

// Implemented by Akash Kumar S

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import ChatBotConextApi from "../Components/Context/ChatBotConextApi";

function ChatbotRenewComponent(props) {
  const authCtx = useContext(ChatBotConextApi);
  const subdata = authCtx.contextValue.subdata;
  console.log(subdata, "renew");

  const [Renewal, setRenewal] = useState([]);

  useEffect(() => {
    const { steps } = props;
    const { entry } = steps;

    const Renewal = async () => {
      try {
        const response = await axios.post(
          "https://erp.impactproducts.in/Chatbot_Api/Book_not_received/new_address_change_ticket",

          {
            sub_no: subdata,
            received_mode: "chatbot",
            description: "renew issue",
            login_id: "1",
            received_date: new Date(),
            ticket_type: "complaint",
          }
        );

        console.log(response.data, "RENEWAL");
        setRenewal(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    Renewal();
  }, []);

  return (
    <div>
      <div style={{ color: "whitesmoke" }}>{Renewal.msg}</div>
      <div style={{ color: "whitesmoke" }}>
        Ticket number is:{Renewal.ticket}
      </div>
      <p style={{ color: "whitesmoke" }}>
        Thank you for contacting us, your issue will be resolved in 42 hours.
      </p>
    </div>
  );
}

export default ChatbotRenewComponent;
