// Code for Chatbot Raise Ticket....

// Implemented by Akash Kumar S

import React, { useEffect, useState, useContext } from "react";

import PropTypes from "prop-types";

// import MonthPicker from "simple-react-month-picker";

// import DatePicker from "react-multi-date-picker";

import axios from "axios";
import ChatBotConextApi from "./Context/ChatBotConextApi";
// import { Button } from "react-bootstrap";

const ChatbotBookTicketComponent = (props) => {
  const authCtx = useContext(ChatBotConextApi);
  const subdata = authCtx.contextValue.subdata;
  const monthissue = authCtx.contextValue.monthissue;
  console.log(monthissue, "monthissue");

  const [TicketsRaised, setTicketsRaised] = useState([]);

  // const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

  useEffect(() => {
    const { steps } = props;
    const { entry } = steps;

    props.triggerNextStep();

    // setState({ entry });

    // console.log(props, "beforesending");

    // console.log(entry, "name, gender, age ");

    const RaiseTickets = async () => {
      try {
        const response = await axios.post(
          "https://erp.impactproducts.in/Chatbot_Api/Book_not_received/save_new_ticket",

          {
            //  entry: "9605490510"
            sub_no: subdata,
            received_mode: "chatbot",
            description: "book not received",
            login_id: "1",
            month: monthissue[0],
            received_date: new Date(),
            ticket_type: "Resend",
          }
        );

        console.log(response.data, "tickets");
        setTicketsRaised(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    RaiseTickets();
  }, []);

  return (
    <div>
      <div style={{ color: "whitesmoke" }}>{TicketsRaised.msg}</div>
      <div style={{ color: "whitesmoke", marginTop: "0.5rem" }}>
        Ticket number is:{TicketsRaised.ticket}.
      </div>
      <p style={{ color: "whitesmoke", marginTop: "0.5rem" }}>
        Thank you for contacting us, your issue will be resolved in 42 hours.
      </p>
    </div>
  );
};

export default ChatbotBookTicketComponent;
