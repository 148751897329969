// Code for Chatbot CustomerDetails....

// Code implemented by Akash kumar S

import React, { useState, useEffect, useContext } from "react";

import { Button } from "react-bootstrap";

import ChatBotConextApi from "../Components/Context/ChatBotConextApi";
// import ChatbotAddressComponent from "./ChatbotAddressComponent";
import axios from "axios";

const Post = (props) => {
  const { steps } = props;
  const { entry } = steps;

  const authCtx = useContext(ChatBotConextApi);
  const login = authCtx.contextValue.login;
  const Tickets = authCtx.contextValue.Tickets;

  const [ResponState, setResponseState] = useState([]);

  const [isloading, setIsloading] = useState(true);

  const TryagainHandler = () => {
    props.triggerNextStep({ trigger: "subscribe new magazine" });
  };

  const SubHandler = (address_id, sub_no) => {
    login(address_id);
    Tickets(sub_no);
    props.triggerNextStep();
  };

  useEffect(() => {
    setIsloading(true);
    const GetQuery = async () => {
      try {
        const response = await axios.post(
          "https://erp.impactproducts.in/Chatbot_Api/Checkuser/check_user",

          {
            entry: entry.value,
          }
        );

        if (response.data.msg === "user found") {
          setResponseState(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
      setIsloading(false);
    };
    GetQuery();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {ResponState && ResponState.length > 0 ? (
        <>
          <h5 style={{ textAlign: "center" }}>Your Details</h5>
          <table>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <th>
                  <span>Name</span>
                </th>
                <th></th>
                <th>
                  <span>Subscription No</span>
                </th>
              </tr>

              {ResponState.map((item, index) => (
                <tr style={{ lineHeight: 4 }} key={index}>
                  <td style={{ lineHeight: "1" }}>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "clamp(12px,2.5vw,15px)",
                      }}
                    >
                      {/* className={Styles.ChatButton}> */}
                      {item.name}
                    </div>
                  </td>
                  <td style={{ width: "10px" }}>:</td>
                  <td>
                    <Button
                      onClick={() => SubHandler(item.address_id, item.sub_no)}
                      // className={Styles.ChatButton}
                      style={{
                        width: "100%",
                        fontSize: "clamp(12px,2.5vw,15px)",
                      }}
                    >
                      {item.sub_no}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : !isloading ? (
        <div className="tryAgainContainer">
          No details was found for given input <br />
          <button onClick={TryagainHandler} className="pleaseTryAgain">
            Please Try Again!!!
          </button>
        </div>
      ) : (
        <p>Loading......</p>
      )}
    </div>
  );
};

export default Post;
