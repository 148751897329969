

// Code for Chatbot Footer Components...



// Code Implemented by Akash Kumar S

import React from "react";
import Styles from "../Footer/Footer.module.css";
// import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";


import FooterLogo from "../../Assets/impact_logo_footer.svg";



function Footer() {


  return (
    <div style={{ backgroundColor: " #e6e6e6", position: "relative" }}>
      <div className={Styles.GetinTouchwithUs}>
        <div className={Styles.GetInTouchHeading}>
          <h1>To get in touch with us</h1>
        </div>

        <div>
          <Button className={Styles.ClickHereButton}>Click Here</Button>
        </div>
      </div>

      <div className="Footer">
        <div className={Styles.FooterFlexContainer}>
          <div className={Styles.LogoContainer}>
            <img src={FooterLogo} />
          </div>

          <div className={Styles.QuickLinkContainer}>
            <h3 className={Styles.QuickLinkHeading}>QuickLinks</h3>

            <div className={Styles.QuickLinkContents}>
           
              <a href="https://impactproducts.in/">
              <p>Home</p>
              </a>
              

              <a href="https://impactproducts.in/about_us/">
              <p>About us</p>
              </a>


              <a href="https://impactproducts.in/products/">
              <p>Products us</p>
              </a>


              <a href="https://impactproducts.in/testimonial/">
              <p>Testimonial</p>
              </a>


              <a href="https://impactproducts.in/contact_us/">
              <p>Contact Us</p>
              </a>

              <a href="https://impactproducts.in/terms_condition/">
              <p>Terms and condition</p>
              </a>

              <a href="https://impactproducts.in/privacy_policy/">
              <p>Privacy and policy</p>
              </a>
            </div>
          </div>

          <div className={Styles.ContactContainer}>
            <h1>Contact</h1>

            <div className={Styles.ContactContents}>
              <a href="mailto:info@impactproducts.in">
              <p>info@impactproducts.in</p>
              </a>
              <a href="mailto:editor@impactproducts.in">
              <p>editor@impactproducts.in</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <hr />



      









      <div className={Styles.SubFooter}>
        <div className={Styles.SubFooterHeading}>
          <p>Copyright - Kyurius Tech studio (KTS) - 2021</p>
        </div>
      </div>

    
    </div>
  );
}

export default Footer;
