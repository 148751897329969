import React,{useState} from "react";
import ChatBotBanner from "../Assets/chatbot-blog-banner-72618.png";

function Chatbot() {


 
return (
    <div>
      
          <img width="100%" src={ChatBotBanner} style={{marginBottom:'100px'}}/>
</div>
  );
}

export default Chatbot;
