import "bootstrap/dist/css/bootstrap.min.css";

import Chatbot from "./Pages/Chatbot";
import CollapsibleExample from "./Components/Header/Headers";
import Footer from "./Components/Footer/Footer";
import SimpleForm from "./Pages/ChatbotContent";
import { Login } from "./Components/Context/ChatBotConextApi";
function App() {
  return (
    <Login>
      <div className="App">
        <CollapsibleExample />
        <Chatbot />
        <Footer />
        <SimpleForm />
      </div>
    </Login>
  );
}

export default App;
