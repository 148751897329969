import React, { useState, useEffect, useContext } from "react";
import ChatBotConextApi from "../Components/Context/ChatBotConextApi";
import Styles from "./ChatbotAddressRaise.module.css";
import axios from "axios";

function ChatbotAddressRaiseComponent(props) {
  const authCtx = useContext(ChatBotConextApi);
  const data = authCtx.contextValue.data;
  const subdata = authCtx.contextValue.subdata;
  console.log("addressData", data);
  const [state, setState] = useState({
    address: "",
    address2: "",
    TypeCountry: "",
    Typestate: "",
    TypeCity: "",
    Typelandmark: "",
    TypePincode: "",
  });
  console.log(state, "STATE");

  const [TicketsRaised, setTicketsRaised] = useState([]);

  useEffect(() => {
    const { steps } = props;
    const {
      address,
      address2,
      TypeCountry,
      Typestate,
      TypeCity,
      Typelandmark,
      TypePincode,
    } = steps;
    setState({
      address,
      address2,
      TypeCountry,
      Typestate,
      TypeCity,
      Typelandmark,
      TypePincode,
    });
    console.log(
      address,
      address2,
      TypeCountry,
      Typestate,
      TypeCity,
      Typelandmark,
      TypePincode,
      "successfull"
    );

    const NewAddress = async () => {
      var DesContent =
        "address:" +
        address.value +
        "," +
        "address2:" +
        address2.value +
        "," +
        "country:" +
        TypeCountry.value +
        "," +
        "state:" +
        Typestate.value +
        "," +
        "city:" +
        TypeCity.value +
        "," +
        "landmark:" +
        Typelandmark.value +
        "," +
        "pincode:" +
        TypePincode.value +
        ",";

      try {
        const response = await axios.post(
          "https://erp.impactproducts.in/Chatbot_Api/Book_not_received/save_new_ticket",

          {
            //  entry: "9605490510"
            sub_no: subdata,
            received_mode: "chatbot",
            description: DesContent,
            login_id: "1",
            received_date: new Date(),
            ticket_type: "Address Change",
          }
        );

        console.log(response.data, "tickets");
        setTicketsRaised(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    NewAddress();
  }, []);

  return (
    <div style={{ textAlign: "left" }}>
      <div
        className={Styles.ChatbotRenewTicket}
        style={{ color: "whitesmoke" }}
      >
        {TicketsRaised.msg}.
      </div>
      <div
        className={Styles.ChatbotRenewTicket}
        style={{ color: "whitesmoke" }}
      >
        Ticket number is : {TicketsRaised.ticket}.
      </div>
      <p className={Styles.ChatbotRenewTicket} style={{ color: "whitesmoke" }}>
        Thank you for contacting us, your issue will be resolved in 42 hours.
      </p>
    </div>
  );
}

export default ChatbotAddressRaiseComponent;
