// Code for Chatbook book received complaint...

// Implemented by Akash kumar S

import React, { useState, useContext } from "react";

import DatePicker, { DateObject } from "react-multi-date-picker";
// import { Calendar } from "react-multi-date-picker"

import axios from "axios";
import { Button } from "react-bootstrap";
import ChatBotConextApi from "./Context/ChatBotConextApi";

const ChatbotBookComponent = (props) => {
  const authCtx = useContext(ChatBotConextApi);
  const Month = authCtx.contextValue.Month;
  const subdata = authCtx.contextValue.subdata;
  console.log(subdata, "SUBbOOKS");

  console.log(props.steps.entry.value, "bookreceived");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [value, setValue] = useState(new Date());
  // console.log(value.month.number, "monthvalue");
  //  const[Previousmonth,setPreviousMonth]=useState(new Date())

  // let [date, setDate] = useState(new DateObject());

  // const [UpdatedArray, setUpdatedArray] = useState([]);
  // const[ResponState,setResponseState]=useState([]);
  const [apiResponse, setApiResponse] = useState([]);

  // const HandleChange = (value) => {
  //   setValue(value);

  //   console.log(value, "lue");
  // };

  const HandleClick = async () => {
    // var len = value.length;
    // // console.log(len)
    // for (var i = 0; i < len; i++) {
    //   UpdatedArray.push(value[i].month.number);
    //   console.log(UpdatedArray, "selected one");
    // }

    const UpdatedArray = [];
    UpdatedArray.push(value.month.number);

    // for globallly using
    Month(UpdatedArray);

    try {
      const response = await axios.post(
        "https://erp.impactproducts.in/Chatbot_Api/Book_not_received/get_dispatch_status",
        {
          sub_no: subdata,
          month_of_issue: UpdatedArray,
        }
      );

      console.log(response.data[0], "Month");

      if (response.data[0].msg === "no data") {
        props.triggerNextStep({ value, trigger: "No data" });
        setApiResponse(response.data[0].msg);

        return;
      }
      if (response.data[0][0].dispatch_status === "Processing") {
        setApiResponse(response.data);
        props.triggerNextStep({ value, trigger: "processing" });
        return;
      }

      if (response.data[0][0].dispatch_status === "Dispatched") {
        setApiResponse(response.data);

        props.triggerNextStep({
          value: response.data[0][0].value,
          trigger: "Book not received Response",
        });

        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ position: "relative", zIndex: "1000000" }}>
      {/* <MonthPicker onClick={HandleChange} /> */}

      <div style={{ color: " whitesmoke" }}>Select month:</div>
      <DatePicker
        // multiple={1}
        portal
        onlyMonthPicker={true}
        minDate={new DateObject().subtract(6, "months")}
        maxDate={new DateObject()}
        onChange={setValue}
        placeholder="Select Month:"
        inputClass="custom-input"
        className="custom-calendar"
      />

      {/* <Calendar 
      value={value}
      onChange={setValue}
      portal
      onlyMonthPicker={true}
      minDate={new DateObject().subtract(6, "months")}
      maxDate={new DateObject()}
      onChange={setValue}
      placeholder="Select Month:"
      inputClass="custom-input"
      className="custom-calendar"
    /> */}

      {/* <Calendar value={date} onChange={setDate} /> */}

      <Button onClick={HandleClick}>SEND</Button>

      <div>
        {apiResponse &&
          apiResponse !== "no data" &&
          apiResponse.map((item, index) => (
            <div style={{ color: "white", marginTop: "0.2rem" }} key={index}>
              {months[item[0].month_of_issue - 1]} - {item[0].dispatch_status}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChatbotBookComponent;
