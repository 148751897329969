// Code for declaring Global State....

// Implemented by Akash Kumar S

//1.
import React, { useState, useEffect } from "react";
// import Api2 from "../Api/api2";

// let logoutTimer;

const AuthContext = React.createContext({
  data: "",
  subdata: "",
  monthissue: "",
});

export const Login = ({ children }) => {
  const [data, setdata] = useState("");
  const [subdata, setSubdata] = useState("");
  const [monthissue, setMonthissue] = useState("");

  const loginHandler = (data) => {
    setdata(data);
  };

  const Tickethandler = (subdata) => {
    setSubdata(subdata);
  };
  const Monthhandler = (monthissue) => {
    setMonthissue(monthissue);
  };

  const contextValue = {
    data: data,
    subdata: subdata,
    monthissue: monthissue,

    login: loginHandler,
    Tickets: Tickethandler,
    Month: Monthhandler,
  };

  return (
    <AuthContext.Provider
      value={{
        contextValue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
