// Code for Chatbot New Subscription Component...

// Implemented by Akash Kumar S

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Styles from "./ChatbotSubscription.module.css";

import axios from "axios";

function ChatbotSubscriptionComponent(props) {
  const [state, setState] = useState({ name: "", mobile: "", email: "" });
  const [SubscriptionState, setSubscriptionState] = useState("");

  useEffect(() => {
    const { steps } = props;
    const { name, mobile, email } = steps;
    setState({ name, mobile, email });

    console.log(name, mobile, email, "name, mobile, email ");
    const Subscription = async () => {
      try {
        const response = await axios.post(
          "https://erp.impactproducts.in/Chatbot_Api/Book_not_received/save_new_subscription_enquiry",

          {
            name: name.value,
            mobile: mobile.value,
            emailid: email.value,
          }
        );

        console.log(response.data, "new Subscription");
        setSubscriptionState(response.data);
        //   setRenewal(response.data)
      } catch (error) {
        console.log(error);
      }
    };

    Subscription();
  }, []);

  const { name, mobile, email } = state;

  return (
    <div style={{ width: "100%" }}>
      <h5 style={{ textAlign: "center" }}>Details</h5>

      <table>
        <tbody style={{ textAlign: "center" }} className="subnewDetails">
          <tr>
            <td>Name</td>
            <td>:</td>
            <td>{name.value}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>:</td>
            <td>{mobile.value}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>:</td>
            <td>{email.value}</td>
          </tr>
        </tbody>
      </table>
      {/* <div style={{ textAlign: 'left' }}>
        <p>Name : {name.value}</p>
        <p>Mobile : {mobile.value}</p>
        <p>Email : {email.value}</p>

      </div> */}
    </div>
  );
}
ChatbotSubscriptionComponent.propTypes = {
  steps: PropTypes.object,
};

ChatbotSubscriptionComponent.defaultProps = {
  steps: undefined,
};

export default ChatbotSubscriptionComponent;
